import React, { ReactElement, useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "../../ui-kit/Button";
import { Typography } from "../../ui-kit/Typography";
import Gutter from "../../ui-kit/Gutter";

import { resumePreviewStore } from "../Signup/state";
import { speciesToEmoji } from "../../utils/insurance";
import { useWebTranslation } from "../../translations";
import { logEvent } from "../../analytics";

import TrustPilotStars from "../TrustPilotStars";
import styles from "./Hero.module.css";
import { replaceCommonMarkup } from "utils/utils";
import { isOrg } from "utils/site";

type HeroProps = {
  title: ReactElement | string;
  tagLine?: string | ReactElement;
  usps?: string[];
  buttonTitle?: string;
  onButtonClick?: () => void;
  buttonSubText?: string;
  hideResumeQuoteButton?: boolean;
  imageSrc?: string;
};

export default function Hero({
  title,
  tagLine,
  usps,
  buttonTitle,
  onButtonClick,
  buttonSubText,
  hideResumeQuoteButton = false,
  imageSrc,
}: HeroProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();
  const [signupState, setSignupState] = useState<{
    isLiability: boolean;
    isExperiment: boolean;
    previewId: undefined;
    name: undefined;
    species: undefined;
  } | null>(null);

  const onClick = () => {
    if (typeof onButtonClick === "function") {
      return onButtonClick();
    }

    logEvent("USER_HOMEPAGE_GET_QUOTE");
    router.push("/signup");
    return true;
  };

  useEffect(() => {
    const signupState = resumePreviewStore.load();
    if (signupState?.quotePreviewId) {
      setSignupState({
        isLiability: signupState.isLiability,
        isExperiment: signupState.quotePreviewExperiment,
        previewId: signupState.quotePreviewId,
        name: signupState.name,
        species: signupState.species,
      });
    }
  }, []);

  return (
    <div>
      <main className={styles.container}>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <div className={styles.image}>
              <Image
                src={
                  imageSrc ??
                  `/images/hero-image-cat-dog-round-${router.locale}.png`
                }
                alt="Lassie"
                priority
                width="500"
                height="500"
                sizes="(max-width: 500px) 250px, (max-width: 768px) 300px, (min-width: 1200px) 500px"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={styles.textContent}>
              <div className={styles.titles}>
                <Typography variant="display" className={styles.title}>
                  {title}
                </Typography>
              </div>
              {tagLine && (
                <div data-test-id="tagLine" className={styles.tagLine}>
                  <div>{tagLine}</div>
                </div>
              )}
              {usps && (
                <div data-test-id="tagLine" className={styles.usps}>
                  <ul className={styles.uspList}>
                    {usps.map((usp) => (
                      <li className={styles.uspListItem} key={usp}>
                        <Typography color="black">
                          {replaceCommonMarkup(usp)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div
                style={{
                  maxHeight: "100vh",
                }}
              >
                <div className={styles.button}>
                  <Button
                    big
                    textStyle={{
                      fontSize: 16,
                      fontFamily: "var(--font-eina-bold)",
                    }}
                    variant={"custom"}
                    color={"green200"}
                    onPress={onClick}
                    title={buttonTitle || t("home.sections.0.btn")}
                  />
                  {signupState?.previewId && !hideResumeQuoteButton && (
                    <>
                      <Gutter size={1} />
                      <div className={styles.resumeQuoteButton}>
                        <Button
                          big
                          textStyle={{
                            fontSize: 16,
                            fontFamily: "var(--font-eina-bold)",
                          }}
                          variant={"custom"}
                          color={"white"}
                          textColor="blue300"
                          title={t("home.sections.0.btn_resume_quote", {
                            name: signupState?.name ?? "?",
                            emoji: speciesToEmoji(signupState.species),
                          })}
                          onPress={() => {
                            const isExperiment = signupState?.isExperiment;
                            const isLiability =
                              isExperiment && signupState.isLiability;

                            const page = !isExperiment
                              ? "quote-preview"
                              : !isLiability
                              ? "select-plan"
                              : "select-plan-liability";
                            logEvent("USER_HOMEPAGE_RESUME_QUOTE");
                            router.push(
                              `/signup/${page}?id=${signupState?.previewId}`
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                  {buttonSubText && (
                    <>
                      <Gutter size={1} />
                      <Typography
                        variant="body4"
                        component="div"
                        align="center"
                      >
                        {buttonSubText}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.trustpilot}>
                {!isOrg("swe") && <TrustPilotStars />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
Hero.defaultProps = {
  onButtonClick: false,
  hideResumeQuoteButton: false,
};
