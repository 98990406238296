import React, { FC } from "react";
import { gql } from "@apollo/client";
import { Benefits as BenefitsProps } from "../../../../__generated__/Benefits";
import { ContentfulComponentType } from "../types";
import styles from "./ContentfulBenefits.module.css";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { getIcon } from "../icons";
import { Typography } from "ui-kit/Typography";
import classNames from "classnames";
import { IconFrame } from "ui-kit";

type BenefitItem = {
  title: string | null;
  text: string | null;
  icon: string | null;
};

const SideBySideBenefitItem: FC<{ benefit: BenefitItem }> = ({ benefit }) => {
  const { title, text, icon } = benefit;
  const iconName = getIcon(icon);

  return (
    <FlexRow key={title} alignItems="center">
      <IconFrame icon={iconName} iconSize="M" style="round" />
      <Gutter size={2} horizontal />
      <FlexCol>
        <Typography variant="body1">
          <strong>{title}</strong>
        </Typography>
        <Gutter size={0.5} />
        <Typography>{text}</Typography>
      </FlexCol>
    </FlexRow>
  );
};

const OverUnderBenefitItem: FC<{ benefit: BenefitItem }> = ({ benefit }) => {
  const { title, text, icon } = benefit;
  const iconName = getIcon(icon);

  return (
    <FlexCol key={title} alignItems="center">
      <IconFrame icon={iconName} iconSize="M" style="round" />
      <Gutter size={2} />
      <FlexCol style={{ textAlign: "center" }}>
        <Typography variant="body1">
          <strong>{title}</strong>
        </Typography>
        <Gutter size={0.5} />
        <Typography>{text}</Typography>
      </FlexCol>
    </FlexCol>
  );
};

export const ContentfulBenefits: ContentfulComponentType<BenefitsProps> = ({
  highlight,
  title,
  text,
  benefitItemsCollection,
  benefitLayout,
  c2A,
}) => {
  const benefitItems =
    benefitItemsCollection?.items.flatMap((item) => (item ? [item] : [])) ?? [];

  return (
    <>
      <TextFields highlight={highlight} title={title} text={text} center />

      <div
        className={classNames(
          styles.benefits,
          benefitLayout && styles.sideBySide
        )}
      >
        {benefitItems.map((benefit) =>
          benefitLayout ? (
            <SideBySideBenefitItem key={benefit.title} benefit={benefit} />
          ) : (
            <OverUnderBenefitItem key={benefit.title} benefit={benefit} />
          )
        )}
      </div>
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={4} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </>
  );
};

ContentfulBenefits.fragment = gql`
  fragment Benefits on ComponentBenefits {
    highlight
    title
    text
    benefitItemsCollection(limit: 12) {
      items {
        title
        text
        icon
      }
    }
    benefitLayout
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
