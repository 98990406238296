import Image from "next/image";
import { createElement, useEffect, useMemo, useRef, useState } from "react";
import { useWebTranslation } from "translations";
import { getOrg, Org } from "utils/site";
import { contentfulLocale } from "utils/utils";
import styles from "./ReviewsSection.module.css";
import { AppStores } from "./ReviewsSection/Appstores";

const TrustPilot = () => {
  const { i18n } = useWebTranslation();

  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className={styles.review}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale={contentfulLocale(i18n?.language ?? "en_GB")}
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="60dda23d8223de0001953209"
        data-style-height="40px"
        data-style-width="100%"
        data-theme="light"
      />
    </div>
  );
};

const Konsumenternas = () => {
  return (
    <div className={styles.review}>
      <Image
        src="/images/hero/konsumenternas.svg"
        alt=""
        width={192}
        height={52}
      />
    </div>
  );
};

const reviewWidgets = {
  konsumenternas: Konsumenternas,
  appStores: AppStores,
  trustpilot: TrustPilot,
};

type ReviewWidgets = keyof typeof reviewWidgets;

const getItemsForOrg = (org: Org): ReviewWidgets[] => {
  switch (org) {
    case "ger":
    case "fra":
      return ["trustpilot", "appStores"];
    case "swe":
      return ["konsumenternas", "appStores"];
    default:
      return [];
  }
};

const getItems = () => {
  const org = getOrg();

  const items = getItemsForOrg(org);

  if (items.length > 1) {
    return [...items, ...items.slice(0, 1)];
  }

  return items;
};

const TIMEOUT = 4000;

export const ReviewsSection = () => {
  const [index, setIndex] = useState(0);
  const items = useMemo(() => getItems(), []);

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    const update = () => {
      setIndex((i) => (i + 1) % items.length);
    };

    if (items.length > 1) {
      id = setTimeout(
        update,
        index === items.length - 1 || index === 0 ? TIMEOUT / 2 : TIMEOUT
      );
    }

    return () => {
      clearTimeout(id);
    };
  }, [index, items]);

  return (
    <div className={styles.reviewsSection}>
      <div
        style={{
          transform: `translateX(-${100 * index}%)`,
          transition: index === 0 ? "none" : "transform 1s",
        }}
        className={styles.reviews}
      >
        {items.map((item, index) =>
          createElement(reviewWidgets[item], { key: item + index })
        )}
      </div>
    </div>
  );
};
