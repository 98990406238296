import React, { ReactElement, useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "../../../ui-kit/Button";
import { Typography } from "../../../ui-kit/Typography";
import Gutter from "../../../ui-kit/Gutter";

import { resumePreviewStore } from "../../Signup/state";
import { speciesToEmoji } from "../../../utils/insurance";
import { useWebTranslation } from "../../../translations";
import { logEvent } from "../../../analytics";

import styles from "./StandardHero.module.css";
import { replaceCommonMarkup } from "utils/utils";
import ContentfulMedia, { Asset } from "components-shared/ContentfulMedia";
import { Hero_c2A } from "../../../../__generated__/Hero";
import { ContentfulC2A } from "../ContentfulC2A";
import { getOrg } from "utils/site";
import ShowBlockOnMedia from "ui-kit/ShowBlockOnMedia";
import ShowFlexOnMedia from "ui-kit/ShowFlexOnMedia";
import { ReviewsSection } from "./ReviewsSection";
import { ReferralSection } from "./ReferralSection";

type HeroProps = {
  title: string | null;
  text?: string | null;
  usps?: string[];
  media: Asset;
  c2a?: Hero_c2A | null;
};

const HeroImage = ({ media }: { media: Asset }) => {
  const { t, i18n } = useWebTranslation();

  return (
    <>
      <div className={styles.imageBadge}>
        <Image
          src="/images/hero-avatars.png"
          alt=""
          width={67}
          height={30}
          className={styles.avatars}
        />
        <div className={styles.badgeText}>
          <Typography variant="body1">
            <strong>{t("hero.pets_number")}</strong>
          </Typography>
          <Typography variant="body4">{t("hero.pets_suffix")}</Typography>
        </div>
      </div>
      <div className={styles.image}>
        <ContentfulMedia media={media} sizes="450px" />
      </div>
      <Image
        src={`/images/hero/phone-${getOrg()}-${i18n?.language}.png`}
        width={450}
        height={450}
        alt=""
        className={styles.phone}
      />
    </>
  );
};

export default function Hero({
  title,
  text,
  usps,
  media,
  c2a,
}: HeroProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();
  const [signupState, setSignupState] = useState<{
    isLiability: boolean;
    isExperiment: boolean;
    previewId: undefined;
    name: undefined;
    species: undefined;
  } | null>(null);

  useEffect(() => {
    const signupState = resumePreviewStore.load();
    if (signupState?.quotePreviewId) {
      setSignupState({
        isLiability: signupState.isLiability,
        isExperiment: signupState.quotePreviewExperiment,
        previewId: signupState.quotePreviewId,
        name: signupState.name,
        species: signupState.species,
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div className={styles.title}>
            <Typography variant="h1" color="blue300">
              {title}
            </Typography>
          </div>
          {text && <Typography variant="body2">{text}</Typography>}
          {usps && (
            <div className={styles.usps}>
              <ul className={styles.uspList}>
                {usps.map((usp) => (
                  <li className={styles.uspListItem} key={usp}>
                    <Typography color="black">
                      {replaceCommonMarkup(usp)}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <ShowBlockOnMedia desktop mobile={false} tablet={false}>
            <ReviewsSection />
          </ShowBlockOnMedia>
          <div
            style={{
              maxHeight: "100vh",
            }}
          >
            <div className={styles.button}>
              {c2a ? (
                <span onClick={() => logEvent("USER_HOMEPAGE_GET_QUOTE")}>
                  <ContentfulC2A {...c2a} />
                </span>
              ) : null}
              {signupState?.previewId && (
                <>
                  <Gutter size={2} />
                  <div className={styles.resumeQuoteButton}>
                    <Button
                      textStyle={{
                        fontSize: 16,
                        fontFamily: "var(--font-eina-bold)",
                      }}
                      variant={"custom"}
                      color={"white"}
                      textColor="blue300"
                      title={t("home.sections.0.btn_resume_quote", {
                        name: signupState?.name ?? "?",
                        emoji: speciesToEmoji(signupState.species),
                      })}
                      onPress={() => {
                        const isExperiment = signupState?.isExperiment;
                        const isLiability =
                          isExperiment && signupState.isLiability;

                        const page = !isExperiment
                          ? "quote-preview"
                          : !isLiability
                          ? "select-plan"
                          : "select-plan-liability";
                        logEvent("USER_HOMEPAGE_RESUME_QUOTE");
                        router.push(
                          `/signup/${page}?id=${signupState?.previewId}`
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <ShowFlexOnMedia desktop={false} className={styles.imageContainer}>
              <HeroImage media={media} />
            </ShowFlexOnMedia>
            <ShowBlockOnMedia desktop={false}>
              <ReviewsSection />
            </ShowBlockOnMedia>
          </div>
        </div>
        <ShowBlockOnMedia
          desktop
          mobile={false}
          tablet={false}
          className={styles.imageContainer}
        >
          <HeroImage media={media} />
        </ShowBlockOnMedia>
      </div>
      <ReferralSection />
    </div>
  );
}
