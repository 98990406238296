import Image from "next/image";
import Link from "next/link";
import { getOrg, Org } from "utils/site";
import styles from "./ReferralSection.module.css";

type Referral =
  | "businessinsider"
  | "ft"
  | "grunderszene"
  | "handelsblatt"
  | "techcrunch"
  | "versicherungsw"
  | "wired"
  | "gtai"
  | "aftonbladet"
  | "di"
  | "dn"
  | "c8"
  | "capital"
  | "financialenterprise"
  | "largus";

const referralsMap: Record<Referral, Partial<Record<Org, string>>> = {
  businessinsider: {
    ger: "https://www.businessinsider.de/gruenderszene/business/nach-expansion-in-deutschland-tierversicherungs-startup-lassie-sammelt-23-millionen-ein/",
  },
  ft: {},
  grunderszene: {
    ger: "https://www.businessinsider.de/gruenderszene/business/nach-expansion-in-deutschland-tierversicherungs-startup-lassie-sammelt-23-millionen-ein/",
  },
  handelsblatt: {
    ger: "https://www.handelsblatt.com/vergleich/hundekrankenversicherung-vergleich/",
  },
  techcrunch: {},
  versicherungsw: {
    ger: "https://versicherungswirtschaft-heute.de/unternehmen-und-management/2023-02-06/lassie-chefin-baverud-olsson-im-interview-dem-deutschen-markt-fehlt-ein-praeventiver-proaktiver-ansatz-fuer-haustierversicherungen/",
  },
  wired: {},
  aftonbladet: {},
  di: {},
  dn: {},
  c8: {},
  capital: {},
  financialenterprise: {},
  largus: {},
  gtai: {},
};

const referrals: Record<Org, Array<keyof typeof referralsMap>> = {
  swe: ["dn", "aftonbladet", "di", "ft", "wired", "techcrunch"],
  ger: [
    "handelsblatt",
    "grunderszene",
    "versicherungsw",
    "businessinsider",
    "ft",
    "gtai",
    "techcrunch",
  ],
  fra: ["techcrunch", "ft", "capital", "largus", "c8", "financialenterprise"],
};

export const ReferralSection = () => {
  const org = getOrg();
  const items = referrals[org];

  const renderedItems = items.map((item) => {
    const link = referralsMap[item][org];

    if (link) {
      return (
        <Link href={link} key={item}>
          <Image
            src={`/images/hero/logos/${item}.svg`}
            alt=""
            width={120}
            height={60}
            loading="eager"
          />
        </Link>
      );
    }

    return (
      <Image
        src={`/images/hero/logos/${item}.svg`}
        key={item}
        alt=""
        width={120}
        height={60}
        loading="eager"
      />
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>{renderedItems}</div>
    </div>
  );
};
